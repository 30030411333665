<template>
  <div class="partner">
    <div class="action-bar">
      <router-link to="/partner/create">
        <el-button type="primary" icon="el-icon-edit">新增合作伙伴</el-button>
      </router-link>
      <el-input v-model="keyword" placeholder="输入关键字搜索合作伙伴信息" class="search" @keyup.enter.native="search">
        <el-button icon="el-icon-search" @click="search" slot="append"></el-button>
      </el-input>
    </div>
    <el-card shadow="hover">
      <el-table :data="list" size="mini" v-loading="loading">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div v-html="scope.row.content"></div>
          </template>
        </el-table-column>
        <el-table-column label="ID" prop="id" width="80"></el-table-column>
        <el-table-column label="logo" width="100px">
          <template slot-scope="scope">
            <a :href="scope.row.image_url" target="_blank">
              <img :src="scope.row.image_url" alt="logo" style="width: 50px;">
            </a>
          </template>
        </el-table-column>
        <el-table-column label="合作伙伴" prop="name"></el-table-column>
        <el-table-column label="创建时间" prop="created_at" width="150"></el-table-column>
        <el-table-column label="最后更新" prop="updated_at" width="150"></el-table-column>
        <el-table-column label="状态" width="100">
          <template slot-scope="scope">
            <el-tag size="mini" type="success" v-if="scope.row.state">已发布</el-tag>
            <el-tag size="mini" type="info" v-else>未发布</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <router-link :to="{ name: 'partnerEdit', query: { id: scope.row.id } }">
              <el-button type="primary" size="mini">编辑</el-button>
            </router-link>
            <el-button type="danger" size="mini" @click="deleteItem(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        :currentChange="currentChange"
        :total="total"
        :layout="pageLayout"
        :page-size="pageSize"
        background></el-pagination>
    </el-card>
  </div>
</template>

<script>
import { partnerDelete, partnerList } from '@/request/modules/partner'
import { PAGE_LAYOUT, PAGE_SIZE } from '@/config/app'

export default {
  data () {
    return {
      list: [],
      loading: false,
      page: 1,
      total: 0,
      keyword: '',
      pageLayout: PAGE_LAYOUT,
      pageSize: PAGE_SIZE
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      partnerList({ keyword: this.keyword, page: this.page }).then(resp => {
        this.list = resp.list
        this.total = resp.total
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    search () {
      this.currentChange(1)
    },
    currentChange (page) {
      this.page = page
      this.fetchData()
    },
    deleteItem (row) {
      this.$confirm(`确定删除${row.name}?`, {}).then(() => {
        partnerDelete({ id: row.id }).then(resp => {
          this.$message.success('操作成功!')
          this.fetchData()
        }).catch(() => {})
      })
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
